import avax from "./assets/icons/avax.png";
import bitcoin from "./assets/icons/bitcoin.png";
import cosmos from "./assets/icons/cosmos.png";
import doge from "./assets/icons/doge.png";
import ethereum from "./assets/icons/ethereum.png";
import filecoin from "./assets/icons/filecoin.png";
import osmo from "./assets/icons/osmo.png";
import pepe from "./assets/icons/pepe.png";
import platypus from "./assets/icons/platypus.png";
import shiba from "./assets/icons/shiba.png";
import shitCoin from "./assets/icons/shitCoin.png";
import solana from "./assets/icons/solana.png";
import tether from "./assets/icons/tether.png";
import tw from "./assets/icons/tw.png";
import uniswap from "./assets/icons/uniswap.png";
import xbt from "./assets/icons/xbt.png";
import zcsh from "./assets/icons/zcsh.png";

interface Position {
  x: number;
  y: number;
}

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export interface StretchSizePosition {
  ultraWide: Position;
  wide: Position;
  square: Position;
  short: Position;
  tall: Position;
  ultraTall: Position;
}

interface Bubble extends StretchSizePosition {
  name: string;
  src: string;
  class?: string;
  width: number;
  // opacity: number;
}

export const bubbles: Bubble[] = [
  {
    name: "avax", // red triangle
    src: avax,
    width: 10,
    ultraWide: { x: 30, y: 10 },
    wide: { x: 30, y: 10 },
    square: { x: 30, y: 10 },
    short: { x: 30, y: 10 },
    tall: { x: 30, y: 25 },
    ultraTall: { x: 30, y: 25 },
    class: "logo-lg",
  },
  {
    name: "bitcoin", // orange circle
    src: bitcoin,
    width: 10,
    ultraWide: { x: 25, y: 105 },
    wide: { x: 25, y: 105 },
    square: { x: 35, y: 98 },
    short: { x: 35, y: 98 },
    tall: { x: 35, y: 98 },
    ultraTall: { x: 35, y: 90 },
    class: "logo-sm",
  },
  {
    name: "cosmos", // black atom
    src: cosmos,
    width: 10,
    ultraWide: { x: 83, y: 5 },
    wide: { x: 85, y: -5 },
    square: { x: 80, y: 0 },
    short: { x: 75, y: 0 },
    tall: { x: 75, y: 25 },
    ultraTall: { x: 75, y: 25 },
    class: "logo-sm",
  },
  {
    name: "doge", // yellow dog not shiba
    src: doge,
    width: 10,
    ultraWide: { x: 50, y: -10 },
    wide: { x: 50, y: -10 },
    square: { x: 50, y: -10 },
    short: { x: 50, y: 5 },
    tall: { x: 50, y: 20 },
    ultraTall: { x: 50, y: 20 },
    class: "logo-sm",
  },
  {
    name: "ethereum", // blue diamond
    src: ethereum,
    width: 10,
    ultraWide: { x: 20, y: 20 },
    wide: { x: 20, y: 20 },
    square: { x: 20, y: 20 },
    short: { x: 20, y: 20 },
    tall: { x: 40, y: 0 },
    ultraTall: { x: 40, y: 0 },
    class: "logo-sm",
  },
  {
    name: "filecoin", // blue integral
    src: filecoin,
    width: 10,
    ultraWide: { x: 90, y: 80 },
    wide: { x: 90, y: 80 },
    square: { x: 90, y: 80 },
    short: { x: 82, y: 95 },
    tall: { x: 82, y: 95 },
    ultraTall: { x: 82, y: 90 },
    class: "logo-sm",
  },
  {
    name: "osmo", // purple potion
    src: osmo,
    width: 15,
    ultraWide: { x: 5, y: 0 },
    wide: { x: 5, y: 0 },
    square: { x: 5, y: 0 },
    short: { x: 10, y: 10 },
    tall: { x: 10, y: 10 },
    ultraTall: { x: 10, y: 10 },
    class: "logo-sm",
  },
  {
    name: "pepe", // green frog
    src: pepe,
    width: 10,
    ultraWide: { x: 10, y: 100 },
    wide: { x: 10, y: 100 },
    square: { x: 5, y: 105 },
    short: { x: 10, y: 100 },
    tall: { x: 10, y: 100 },
    ultraTall: { x: 10, y: 100 },
    class: "logo-sm",
  },
  {
    name: "platypus", // purple platypus - not xbt
    src: platypus,
    width: 10,
    ultraWide: { x: 55, y: 105 },
    wide: { x: 50, y: 105 },
    square: { x: 50, y: 95 },
    short: { x: 45, y: 90 },
    tall: { x: 40, y: 80 },
    ultraTall: { x: 40, y: 75 },
    class: "logo-sm",
  },
  {
    name: "shiba", // red dog - not doge
    src: shiba,
    width: 20,
    ultraWide: { x: 95, y: 105 },
    wide: { x: 95, y: 105 },
    square: { x: 90, y: 100 },
    short: { x: 98, y: 98 },
    tall: { x: 98, y: 68 },
    ultraTall: { x: 98, y: 68 },
    class: "logo-sm",
  },
  {
    name: "shitCoin1", // top left
    src: shitCoin,
    width: 20,
    ultraWide: { x: 38, y: -8 },
    wide: { x: 38, y: -8 },
    square: { x: 30, y: -8 },
    short: { x: 30, y: -8 },
    tall: { x: 10, y: -8 },
    ultraTall: { x: 10, y: -8 },
    class: "shitCoin logo-md",
  },
  {
    name: "shitCoin2", // top right
    src: shitCoin,
    width: 20,
    ultraWide: { x: 95, y: 5 },
    wide: { x: 97, y: 5 },
    square: { x: 97, y: 5 },
    short: { x: 97, y: -5 },
    tall: { x: 97, y: -5 },
    ultraTall: { x: 97, y: -5 },
    class: "shitCoin logo-md",
  },
  {
    name: "shitCoin3", // bottom left
    src: shitCoin,
    width: 20,
    ultraWide: { x: 5, y: 80 },
    wide: { x: 5, y: 80 },
    square: { x: 5, y: 80 },
    short: { x: 5, y: 80 },
    tall: { x: 5, y: 80 },
    ultraTall: { x: 5, y: 70 },
    class: "shitCoin logo-md",
  },
  {
    name: "shitCoin4", // bottom right
    src: shitCoin,
    width: 8,
    ultraWide: { x: 75, y: 102 },
    wide: { x: 65, y: 92 },
    square: { x: 65, y: 92 },
    short: { x: 60, y: 92 },
    tall: { x: 65, y: 92 },
    ultraTall: { x: 55, y: 100 },
    class: "shitCoin logo-md",
  },
  {
    name: "solana", // black rainbow S
    src: solana,
    width: 10,
    ultraWide: { x: 20, y: 85 },
    wide: { x: 20, y: 85 },
    square: { x: 20, y: 95 },
    short: { x: 20, y: 90 },
    tall: { x: 20, y: 80 },
    ultraTall: { x: 20, y: 80 },
    class: "logo-sm",
  },
  {
    name: "tether", // Green T with halo
    src: tether,
    width: 20,
    ultraWide: { x: 79, y: 80 },
    wide: { x: 75, y: 95 },
    square: { x: 78, y: 85 },
    short: { x: 75, y: 80 },
    tall: { x: 75, y: 80 },
    ultraTall: { x: 70, y: 75 },
    class: "logo-sm",
  },
  {
    name: "tw", // purple coin
    src: tw,
    width: 20,
    ultraWide: { x: 68, y: -10 },
    wide: { x: 70, y: -10 },
    square: { x: 70, y: -10 },
    short: { x: 65, y: -10 },
    tall: { x: 70, y: -10 },
    ultraTall: { x: 70, y: -10 },
    class: "logo-md",
  },
  {
    name: "uniswap", // pink unicorn
    src: uniswap,
    width: 10,
    ultraWide: { x: 10, y: 35 },
    wide: { x: 10, y: 35 },
    square: { x: 10, y: 35 },
    short: { x: 5, y: 30 },
    tall: { x: 10, y: 35 },
    ultraTall: { x: 10, y: 35 },
    class: "logo-sm",
  },
  {
    name: "xbt", // black platypus - not platypus
    src: xbt,
    width: 10,
    ultraWide: { x: 100, y: 45 },
    wide: { x: 100, y: 45 },
    square: { x: 95, y: 25 },
    short: { x: 97, y: 18 },
    tall: { x: 97, y: 18 },
    ultraTall: { x: 97, y: 28 },
    class: "logo-sm",
  },
  {
    name: "zcsh", // Z - Cash
    src: zcsh,
    width: 10,
    ultraWide: { x: 90, y: 45 },
    wide: { x: 90, y: 45 },
    square: { x: 97, y: 55 },
    short: { x: 90, y: 80 },
    tall: { x: 80, y: 0 },
    ultraTall: { x: 80, y: 5 },
    class: "logo-sm",
  },
];
