import React, { useEffect, useState, useTransition } from "react";
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  useSprings,
} from "react-spring";
import { useDeviceOrientation } from "./Mobile";

interface AnimProps {
  delay?: number;
  children: React.ReactNode;
  x: number;
  y: number;
  styles?: any;
  ref?: any;
  className?: string;
}

interface FollowMouseProps {
  children: React.ReactNode;
  reference: any;
  className?: string;
}

export const BobbleAnim = ({ children, delay = 0 }: AnimProps) => {
  const styles = useSpring({
    loop: { reverse: true },
    from: {
      x: 0,
      y: 0,
      scale: 1,
    },
    to: async (next) => {
      while (true) {
        await next({
          x: 10 + Math.random() * 5 - 2.5, // Randomize x within a range
          y: 10 + Math.random() * 5 - 2.5, // Randomize y within a range
          scale: 1.05,
        });
      }
    },
    config: { duration: 2000 },
    delay: delay,
  });
  return <animated.div style={styles}>{children}</animated.div>;
};

export const BounceAndFade = ({ children, delay = 0 }: AnimProps) => {
  const [state, toggle] = useState(true);
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1000 },
  });
  return (
    <animated.div
      style={{
        opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
        scale: x.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
        }),
      }}
    >
      {children}
    </animated.div>
  );
};

export const Explode = ({ children, delay = 0, x, y, styles }: AnimProps) => {
  const springStyles = useSpring({
    // loop: true,
    // loop: { reverse: true },
    from: { transform: "translateX(0px) translateY(0px)" },
    to: { transform: `translateX(${x}px) translateY(${y}px)` },
    config: { mass: 2, tension: 200, friction: 9 },
  });
  return (
    <animated.div style={{ ...springStyles, ...styles }}>
      {children}
    </animated.div>
  );
};

// export const ExplodeAndFloat = ({
//   children,
//   delay = 0,
//   x,
//   y,
//   styles,
// }: AnimProps) => {
//   const explosionApi = useSpringRef();
//   const floatApi = useSpringRef();

//   const explosionStyles = useSpring({
//     ref: explosionApi,
//     // loop: true,
//     // loop: { reverse: true },
//     from: { transform: "translateX(0px) translateY(0px)" },
//     to: { transform: `translateX(${x}px) translateY(${y}px)` },
//     config: { mass: 2, tension: 200, friction: 9 },
//   });
//   const floatStyles = useSpring({
//     ref: floatApi,
//     loop: { reverse: true },
//     from: {
//       // x: 0,
//       // y: 0,
//       scale: 1,
//     },
//     reset: true,
//     // to: {
//     //   x: 10,
//     //   y: 10,
//     //   scale: 1.2,
//     // },
//     to: async (next) => {
//       while (true) {
//         await next({
//           // x: 10 + Math.random() * 5 - 2.5, // Randomize x within a range
//           // y: 10 + Math.random() * 5 - 2.5, // Randomize y within a range
//           scale: 1.25,
//         });
//       }
//     },
//     // config: { duration: 2000 },
//     delay: delay,
//   });

//   // useChain(open ? [springApi, transApi] : [transApi, springApi], [
//   useChain(
//     [explosionApi, floatApi],
//     [
//       0,
//       // open ? 0.1 : 0.6,
//       0.1,
//     ]
//   );

//   return (
//     <animated.div
//       style={{
//         ...floatStyles,
//         // ...explosionStyles,
//         ...styles,
//       }}
//     >
//       <animated.div style={{ ...floatStyles }}>{children}</animated.div>
//     </animated.div>
//   );
// };

const calc = (
  x: number,
  y: number,
  rect: { top: number; height: number; left: number; width: number }
) => [
  -(y - rect.top - rect.height / 2) / 15,
  (x - rect.left - rect.width / 2) / 15,
  1.1,
];

const trans = (x: any, y: any, s: any) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const FollowMouse = ({
  children,
  className,
  reference,
}: FollowMouseProps) => {
  const config = {
    mass: 0.3,
    tension: 93,
    friction: 26,
    clamp: true,
    precision: 0.01,
    velocity: 0,
  };

  const [{ xys }, api] = useSpring(
    () => ({ xys: [0, 0, 1], config }),
    [config]
  );

  const handleMouseLeave = () =>
    api.start({
      xys: [0, 0, 1],
    });

  const handleMouseMove = (e: { clientX: any; clientY: any }) => {
    if (reference.current === null) return;

    const rect = reference.current.getBoundingClientRect();
    api.start({
      xys: calc(e.clientX, e.clientY, rect),
    });
  };

  return (
    <animated.div
      className={className}
      style={{ transform: xys.to(trans) }}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {children}
    </animated.div>
  );
};

export const FollowGyro = ({
  children,
  className,
  reference,
}: FollowMouseProps) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const { requestAccess, revokeAccess } =
    useDeviceOrientation();
  const onToggle = (): void => {
    !isToggled ? requestAccess() : revokeAccess();
    setIsToggled(!isToggled);
  };

  const config = {
    mass: 0.3,
    tension: 93,
    friction: 26,
    clamp: true,
    precision: 0.01,
    velocity: 0,
  };

  const [{ xys }, api] = useSpring(
    () => ({ xys: [0, 0, 1], config }),
    [config]
  );

  const handleMouseLeave = () =>
    api.start({
      xys: [0, 0, 1],
    });

  const handleOrientation = (event: any) => {
    const { beta, gamma } = event;
    // Adjust these values based on your specific needs
    const x = beta - 45; // assuming beta [-90, 90]
    const y = gamma; // assuming gamma [-45, 45]

    api.start({ xys: [x, y, 1] });
  };

  useEffect(() => {
    if (
      typeof window.DeviceOrientationEvent !== "undefined" &&
      "ontouchstart" in window
    ) {
      // Mobile device
      window.addEventListener("deviceorientation", handleOrientation);
    } else {
      // Desktop device
      // @ts-ignore
      const handleMouseMove = (e) => {
        if (reference.current === null) return;

        const rect = reference.current.getBoundingClientRect();
        api.start({ xys: calc(e.clientX, e.clientY, rect) });
      };

      reference.current.addEventListener("mousemove", handleMouseMove);
      return () => {
        reference.current.removeEventListener("mousemove", handleMouseMove);
      };
    }

    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, [reference, api]);

  return (
    <>
      <button
        style={{ position: "absolute", left: 0, top: 0, zIndex: 999 }}
        onClick={() => onToggle()}
      >
        Enable/Disable the Awesome
      </button>
      <animated.div
        className={className}
        style={{ transform: xys.to(trans) }}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleOrientation}
      >
        {children}
      </animated.div>
    </>
  );
};
