import { useEffect, useRef, useState } from "react";
import "./App.css";
import "./Logos.css";
import "./Unstoppable.css";

import { BrowserView, MobileView } from "react-device-detect";
import { Explode, FollowGyro, FollowMouse } from "./Animation";
import unstoppable from "./assets/unstoppable.png";
import { Breakpoint, StretchSizePosition, bubbles } from "./bubbles";
import { useWindowSize } from "./hooks";

function App() {
  const [isFollowingEnabled, setIsFollowingEnabled] = useState(true);
  const unstoppableRef = useRef<HTMLDivElement>(null);
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );
  const [isExploded, setIsExploded] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsExploded(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const [width, height] = useWindowSize();
  const middleX = Math.floor(width / 2);
  const middleY = Math.floor(height / 2);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", checkOrientation);
    return () => window.removeEventListener("resize", checkOrientation);
  }, []);

  const [breakpoint, setBreakPoint] = useState<Breakpoint>("xs");
  const [stretchSize, setStretchSize] =
    useState<keyof StretchSizePosition>("square");

  useEffect(() => {
    if (0 < width && width < 600) {
      return setBreakPoint("xs");
    }
    if (600 < width && width < 960) {
      return setBreakPoint("sm");
    }
    if (960 < width && width < 1280) {
      return setBreakPoint("md");
    }
    if (1280 < width && width < 1920) {
      return setBreakPoint("lg");
    }
    if (1920 < width && width < 2600) {
      return setBreakPoint("xl");
    }
    if (width >= 2600) {
      return setBreakPoint("xxl");
    }
  }, [width]);

  useEffect(() => {
    const ratio = height / width;

    if (ratio > 2) {
      return setStretchSize("ultraTall");
    }
    if (ratio > 1.5) {
      return setStretchSize("tall");
    }
    if (ratio > 1) {
      return setStretchSize("short");
    }
    if (ratio > 0.75) {
      return setStretchSize("square");
    }
    if (ratio > 0.4) {
      return setStretchSize("wide");
    }
    return setStretchSize("ultraWide");
  }, [width, height]);

  const toggleAnimation = () => {
    console.log("click");
    setIsFollowingEnabled(!isFollowingEnabled);
  };

  return (
    <div className="App" ref={unstoppableRef}>
      <BrowserView>
        <button className="follow-toggle" onClick={toggleAnimation}>
          {isFollowingEnabled ? "Disable the Awesome" : "Enable the Awesome"}
        </button>
        {isFollowingEnabled ? (
          <FollowMouse
            className={`unstoppable unstoppable-${stretchSize}`}
            reference={unstoppableRef}
          >
            <img
              className={`unstoppable unstoppable-${stretchSize}`}
              src={unstoppable}
              alt="logo"
              onClick={() => setIsExploded(!isExploded)}
            />
          </FollowMouse>
        ) : (
          <img
            className={`unstoppable unstoppable-${stretchSize}`}
            src={unstoppable}
            alt="logo"
            onClick={() => setIsExploded(!isExploded)}
          />
        )}
      </BrowserView>
      <MobileView>
        <FollowGyro
          className={`unstoppable unstoppable-${stretchSize}`}
          reference={unstoppableRef}
        >
          <img
            className={`unstoppable unstoppable-${stretchSize}`}
            src={unstoppable}
            alt="logo"
            onClick={() => setIsExploded(!isExploded)}
          />
        </FollowGyro>
      </MobileView>

      <div className="logo-container">
        {bubbles.map((bubble, i) => {
          return (
            <Explode
              key={`${bubble.name}-${i}`}
              x={
                !isExploded
                  ? 0
                  : ((bubble[stretchSize].x * width) / 100 - middleX) / 1.1
              }
              y={
                !isExploded
                  ? 0
                  : ((bubble[stretchSize].y * height) / 100 - middleY) / 1.5
              }
              styles={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "auto",
              }}
            >
              <img
                className={`${bubble.name} ${bubble.name}${
                  isPortrait ? "-y" : "-x"
                } ${bubble.name}-${breakpoint} bp-${breakpoint} ${
                  bubble.class
                } absolute-middle`}
                src={bubble.src}
                alt="logo"
              />
            </Explode>
          );
        })}
      </div>
      <div
        className={`description-text ${
          isPortrait ? "description-text-y" : "description-text-x"
        }`}
      >
        <b>light clients</b> n. software designed for resource constrained
        devices to confirm blockchain data
      </div>
      {process.env.NODE_ENV !== "production" && (
        <b className="dev-note">
          {breakpoint}--
          {stretchSize}
        </b>
      )}
    </div>
  );
}

export default App;
